import styles from 'themes/default/styles/Home.module.css';
import Builder from '@/template/cms-views/builder';
import CMS from "utils/cms";
import React from 'react'

export default function Home(props:any) {

    const {rows} = props;
    
    return (
        <div className={styles.container}>
            <Builder blocks={rows || []} api_id={CMS.API_ID_SLOTS}/>
        </div>
    )
}